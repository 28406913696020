import React, { useState, useEffect } from "react";
import {
  FaRegHeart,
  FaChevronDown,
  FaChevronUp,
  FaHeart,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/url";
import {
  addToCart,
  fetchProductsByCategory,
} from "../../../store/slices/productSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../store/slices/wishlistSlice";
import Spinner from "../../Spinner";

const MenBracelets = () => {
  const [sortedProducts, setSortedProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { categoryProducts, loading: productLoading } = useSelector(
    (state) => state.products
  );
  const wishlist = useSelector((state) => state.wishlist.wishlist);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/category/bracelets`);
        const banners = response.data.data.banners;
        setBanner(banners.find((b) => b.category === "Bracelets"));
      } catch (err) {
        setError("Failed to fetch banner");
      } finally {
        setLoading(false);
      }
    };

    dispatch(fetchProductsByCategory("Bracelets"));
    fetchBanner();
  }, [dispatch]);

  useEffect(() => {
    setSortedProducts(categoryProducts.Bracelets || []);
  }, [categoryProducts]);

  const handleAddToCart = (id) => {
    dispatch(addToCart(id));
  };

  const handleWishlistToggle = (product) => {
    const isInWishlist = wishlist.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
    } else {
      dispatch(addToWishlist(product));
    }
  };

  const handleSort = (order) => {
    const sorted = [...sortedProducts].sort((a, b) =>
      order === "lowToHigh"
        ? a.discountPrice - b.discountPrice
        : b.discountPrice - a.discountPrice
    );
    setSortedProducts(sorted);
    setIsDropdownOpen(false);
  };

  const filteredProducts = sortedProducts.filter((product) =>
    product.productCode.includes("MB")
  );

  if (loading) return <Spinner />;
  if (error) return <div className="text-red-500 text-center">{error}</div>;

  return (
    <div className="font-poppins">
      {/* Banner */}
      <div className="relative w-full h-auto overflow-hidden">
        {banner ? (
          <img
            src={banner.imageLink}
            alt={banner.description}
            className="w-full h-32 sm:h-64 lg:h-96 object-cover"
          />
        ) : (
          <div className="h-32 sm:h-64 lg:h-96 bg-gray-200 flex items-center justify-center">
            No Banner Available
          </div>
        )}
      </div>

      {/* Breadcrumb */}
      <div className="bg-[#E9C876] py-6 sm:py-8">
        <div className="container mx-auto text-center">
          <nav className="inline-flex space-x-2 text-gray-700">
            <Link to="/" className="hover:text-gray-900">
              Home
            </Link>
            <span>&gt;</span>
            <Link to="/shop" className="hover:text-gray-900">
              Shop
            </Link>
            <span>&gt;</span>
            <span className="text-[#5C2020] font-bold">Bracelets</span>
          </nav>
        </div>
      </div>

      {/* Sort and Product Grid */}
      <div className="container mx-auto p-4 sm:p-6">
        <div className="flex justify-between items-center mb-6 relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center text-gray-700"
          >
            Sort by
            {isDropdownOpen ? (
              <FaChevronUp className="ml-2" />
            ) : (
              <FaChevronDown className="ml-2" />
            )}
          </button>
          {isDropdownOpen && (
            <div className="absolute top-10 right-0 bg-white border rounded shadow-lg z-10">
              <ul className="text-left">
                <li>
                  <button
                    onClick={() => handleSort("lowToHigh")}
                    className="block px-4 py-2 hover:bg-gray-100"
                  >
                    Low to High
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleSort("highToLow")}
                    className="block px-4 py-2 hover:bg-gray-100"
                  >
                    High to Low
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Product Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {productLoading ? (
            <Spinner />
          ) : (
            filteredProducts.map((product) => (
              <div key={product._id} className="p-2">
                <div className="bg-white rounded-lg overflow-hidden relative group">
                  <div className="w-full h-[180px] lg:h-[600px] flex items-center justify-center bg-gray-100 relative">
                    <Link
                      to={`/product/${product._id}`}
                      className="w-full h-full"
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-full rounded-lg"
                      />
                    </Link>
                    <button
                      onClick={() => handleWishlistToggle(product)}
                      className="absolute top-4 right-4 text-2xl hover:text-red-600 transition-colors"
                    >
                      {wishlist.some((item) => item._id === product._id) ? (
                        <FaHeart color="red" />
                      ) : (
                        <FaRegHeart color="black" />
                      )}
                    </button>
                  </div>
                  <div className="p-4 text-left">
                    <h3
                      className="text-sm sm:text-lg lg:text-xl font-semibold mb-1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "25ch",
                      }}
                    >
                      {product.name}
                    </h3>
                    <div className="flex flex-col sm:flex-row items-start sm:items-center space-x-0 sm:space-x-2 mb-4">
                      <div className="flex items-center space-x-2">
                        <span className="text-lg sm:text-xl lg:text-2xl font-bold text-red-600">
                          ₹{product.discountPrice}
                        </span>
                        <span className="text-sm sm:text-base lg:text-xl line-through text-[#969595]">
                          ₹{product.price}
                        </span>
                      </div>
                      <span className="text-sm sm:text-base lg:text-lg text-[#5C2028] font-medium mt-2 sm:mt-0 ml-2">
                        ({product.discountPercentage}% OFF)
                      </span>
                    </div>
                    <button
                      className="w-full py-2 bg-[#E9C876] text-white font-bold rounded-lg hover:bg-[#d1a963] transition duration-200 text-[14px] lg:text-[16px]"
                      onClick={() => handleAddToCart(product._id)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MenBracelets;
